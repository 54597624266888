const IMAGES = {
  // phonepe: require("../img/products/phonepe.png"),
  // gpay: require("../img/products/gpay.png"),
  // paytm: require("../img/products/paytm.png"),
  // support
  support: require("../img/contact/support.png"),
  // home slider
  paytm: require("../img/home/paytm.webp"),
  gpay: require("../img/home/gpay.webp"),
  phonepe: require("../img/home/phonepe.webp"),
  upi: require("../img/home/upi.webp"),
};

export default IMAGES;
